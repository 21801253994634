import React, { useRef, useEffect, useState, useContext, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import PrivateRoute from '../../components1/PrivateRoute';
import UserDataWrapper, { EditableContext } from './UserDataWrapper';

// import {Layout as LayoutUser} from '../components1/layoutUser';
import Section from '../../components1/Section';
import { Text, Bold } from '../../components1/Texts';
import Separator from '../../components1/Separator';
import Carousel from '../../components1/carousel';
import OrderPageTab from '../firstOrder/OrderPage';
import FirstOrderPage1 from '../firstOrder/FirstOrderPage1';
import FirstOrderPage2 from '../firstOrder/FirstOrderPage2';
import FirstOrderPage3 from '../firstOrder/FirstOrderPage3';
import FlexDiv from '../../components1/FlexDiv';
import { CabadiciButton, BackButton } from '../../components1/buttons';
import { BackIcon } from '../../components1/Icons';
import { ActivateSubscriptionButton, SuspendSubscriptionButton } from './components/buttons';
import { ShortBasketContent } from '../BasketContent';

import ConfirmOrderModal from '../modals/ConfirmOrder';
import AlreadyOrderedModal from '../modals/AlreadyOrdered';
import AlreadyOrderedModalFirst from '../modals/AlreadyOrderedFirst';
import UpfrontPaymentModal from '../modals/UpfrontPaymentModal';
import OrderSuccessModal from '../modals/OrderSuccess';
// import connectWithStore from '../stores';
import { updateUSerData, subscriptionDeactivate, subscriptionReactivate, updateUserProfil, updateOrderFromApi } from '../../actions';
import { getNextOrderableWeekToken } from '../../services/dates';
import { getUserRef, getCurrentOrder } from '../../services';

const CarouselInsideWrapper = ({ children, goToPrevSlide, goToNextSlide }) => (
    <>
        <ShortBasketContent />
        {children({ goToPrevSlide, goToNextSlide })}
    </>
);

// export default ConnectedUser;
const MyCabadici = ({
    user, authUserData, updateSubscriptionDeactivate, updateSubscriptionReactivate,
    updateUserProfil, updateUSerData, updateOrderFromApi,
    isLastSubscriptionsDeactivate, areAllSubscriptionsDeactivate,
    modalRef, subscriptionModalRef,
    deleteOrderById, isFirstOrder, isUpfrontPayment,
}) => {
    const alreadyOrderedRef = useRef(null);
    const alreadyOrderedFirstRef = useRef(null);
    const upfrontPaymentRef = useRef(null);

    // const [orderUpdateId, setOrderUpdateId] = useState(null);
    const { currentWeekOrderId } = useContext(EditableContext);

    // componentDidUpdate
    useEffect(() => {
        console.log({isFirstOrder, isUpfrontPayment, currentWeekOrderId});
        // display alert if already ordered or not allowed to update
        if (isFirstOrder) {
            return alreadyOrderedFirstRef.current.openModal();
        }
        if (isUpfrontPayment) {
            return upfrontPaymentRef.current.openModal();
        }
        if (currentWeekOrderId) {
            return alreadyOrderedRef.current.openModal();
        }
        /*
        getCurrentOrder(getNextOrderableWeekToken())
            .then((querySnapshot) => {
                if (!querySnapshot.empty && querySnapshot.docs[0].data().isFirstOrder) {
                    alreadyOrderedFirstRef.current.openModal();
                } else if (!querySnapshot.empty) {
                    // already orderer for this week
                    const data = querySnapshot.docs[0].data();
                    // console.log(data);
                    updateOrderFromApi(data);
                    setOrderUpdateId(data.orderId);
                    alreadyOrderedRef.current.openModal();
                }
            })
         */
    }, [isFirstOrder, isUpfrontPayment, currentWeekOrderId]);

    const wrapperRef = useRef(null);

    const orderSuccessModalRef = useRef(null);


    const scrollTo = () => {
        // document.getElementById('FirstOrderScroller').scrollTo(0, 0);
        window.scrollTo(0, wrapperRef.current.offsetTop);
    };

    return (
        <>
            <Carousel isModal={false} ref={wrapperRef} >
                {
                    [
                        <CarouselInsideWrapper>{
                            ({goToNextSlide, goToPrevSlide}) => {
                                return (
                                    <>
                                        <Section>
                                            <FirstOrderPage1
                                                css={`text-align: center;`}
                                                isSuscriber={user.isSubscriber}
                                                isNotCustomer={!authUserData.isCustomer}
                                                showSubscriptionOptions={!user.isSubscriber}
                                                showSubscriptionSuspendButton={!isLastSubscriptionsDeactivate}
                                                showReactiveSubscriptionButton={isLastSubscriptionsDeactivate}
                                                goToNextSlide={goToNextSlide}
                                                goToPrevSlide={goToPrevSlide}
                                                scrollTo={scrollTo}
                                                innerModalRef={modalRef}
                                            />
                                        </Section>
                                        <Separator strokeColor="vertFaible" strokeWidth="0.5rem" />
                                        <FlexDiv
                                            style={{
                                                padding: '20px 0',
                                            }}
                                            css={`background-color: ${props => props.theme.color.beigeFaible};`}
                                        >
                                            <Section>
                                                <Text
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                    css={`
                                                        @media only screen and (max-width: 599px) {
                                                            font-size: 0.875rem !important;
                                                        }
                                                    `}
                                                >
                                                    <Bold
                                                        css={`
                                                            @media only screen and (max-width: 599px) {
                                                                font-size: 0.875rem !important;
                                                            }
                                                        `}
                                                    >Rappel :</Bold>
                                                    {' si vos préférences ne sont pas modifiées d\'ici dimanche soir minuit, nous nous rappellerons de vos derniers choix !'}
                                                    <br />
                                                    {'Vous recevrez donc le même nombre et le même type de recettes,'}
                                                    <br />
                                                    {'les mêmes petits compléments d’épicerie éventuels, suivant le dernier mode de livraison sélectionné.'}
                                                </Text>
                                            </Section>
                                        </FlexDiv>
                                        <FlexDiv
                                            style={{justifyContent: 'center'}}
                                            css={`background-color: ${props => props.theme.color.jauneFort};`}
                                        >
                                            {
                                                user.isSubscriber && (
                                                    !areAllSubscriptionsDeactivate ? (
                                                        <SuspendSubscriptionButton
                                                            deleteOrderById={deleteOrderById}
                                                            updateSubscriptionDeactivate={updateSubscriptionDeactivate}
                                                        />
                                                    ) : (
                                                        <ActivateSubscriptionButton
                                                            updateSubscriptionReactivate={updateSubscriptionReactivate}
                                                        />
                                                    )
                                                )
                                            }
                                        </FlexDiv>
                                    </>
                                )
                            }
                        }
                        </CarouselInsideWrapper>,
                        <CarouselInsideWrapper>
                            {
                                ({goToNextSlide, goToPrevSlide}) => {
                                    return (
                                        <>
                                            <Section>
                                                <FirstOrderPage2
                                                    noButtons
                                                    goToNextSlide={goToNextSlide}
                                                    goToPrevSlide={goToPrevSlide}
                                                    scrollTo={scrollTo}
                                                />
                                            </Section>
                                            <FlexDiv style={{
                                                justifyContent: 'space-evenly',
                                                alignItems: 'flex-end'
                                            }}>
                                                <FlexDiv
                                                    style={{justifyContent: 'center', flex: 1}}
                                                >
                                                    <BackButton
                                                        size="1.115rem"
                                                        color="rougeFort"
                                                        onClick={() => {
                                                            goToPrevSlide();
                                                            scrollTo();
                                                        }}

                                                    />
                                                </FlexDiv>
                                                <FlexDiv
                                                    style={{justifyContent: 'center', flex: 3}}
                                                >
                                                    <CabadiciButton
                                                        color="rougeFaible"
                                                        onClick={() => {
                                                            goToNextSlide();
                                                            scrollTo();
                                                        }}
                                                        css={`
                                                            @media only screen and (max-width: 599px) {
                                                                font-size: 1.625rem !important;
                                                            }
                                                        `}
                                                    >
                                                        suivant
                                                    </CabadiciButton>
                                                </FlexDiv>
                                                <FlexDiv
                                                    style={{justifyContent: 'center', flex: 1}}
                                                />
                                            </FlexDiv>
                                            <Separator strokeColor="vertFaible" strokeWidth="0.5rem" />
                                        </>
                                    )
                                }
                            }
                        </CarouselInsideWrapper>,
                        <CarouselInsideWrapper>
                            {
                                ({goToNextSlide, goToPrevSlide}) => {
                                    const confirmModalRef = useRef(null);
                                    return (
                                        <>
                                            <FirstOrderPage3
                                                noButtons
                                                goToNextSlide={goToNextSlide}
                                                goToPrevSlide={goToPrevSlide}
                                                scrollTo={scrollTo}
                                                innerNextTab={()=> confirmModalRef.current.setData({})}
                                            />
                                            <Separator strokeColor="vertFaible" strokeWidth="0.5rem" />
                                            <ConfirmOrderModal
                                                ref={confirmModalRef}
                                                orderSuccessModalRef={orderSuccessModalRef}
                                                isAnOrderUpdate={!!currentWeekOrderId}
                                                orderId={currentWeekOrderId}
                                                isNotCustomer={!authUserData.isCustomer}
                                            />
                                        </>
                                    )
                                }
                            }
                        </CarouselInsideWrapper>,
                    ]
                }
            </Carousel>
            <AlreadyOrderedModal
                ref={alreadyOrderedRef}
                orderSuccessModalRef={'this.orderSuccessModalRef'}
                onSubmit={() => {
                    // noop
                    // console.log(logInformation())
                }}
            />
            <AlreadyOrderedModalFirst
                ref={alreadyOrderedFirstRef}
                orderSuccessModalRef={'this.orderSuccessModalRef'}
                onSubmit={() => {
                    // alert('ok');
                    // console.log(logInformation())
                }}
            />
            <UpfrontPaymentModal
                ref={upfrontPaymentRef}
                orderSuccessModalRef={'this.orderSuccessModalRef'}
                onSubmit={() => {
                    // alert('ok');
                    // console.log(logInformation())
                }}
            />
            <OrderSuccessModal ref={orderSuccessModalRef} />
        </>
    )
}

export default PrivateRoute(
    UserDataWrapper(MyCabadici));
