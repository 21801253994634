import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Modalize from '../../components/Modalizer_';
import FlexDiv from '../../components1/FlexDiv';
import { Text, Title } from '../../components1/Texts';
import { CabadiciButton, BackButton } from '../../components1/buttons';
import PaymentPage from '../payment/PaymentPage';

const ConfirmOrder = ({ closeModal, orderSuccessModalRef, data, isAnOrderUpdate, orderId, isNotCustomer}) => {
    const PaymentRef = useRef(null);
    return (
        <>
            <PaymentPage fromUserAccount ref={PaymentRef} orderSuccessModalRef={orderSuccessModalRef}/>
            <FlexDiv style={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-end'
            }}>
                <FlexDiv
                    style={{justifyContent: 'flex-start', flex: 1}}
                >
                    <BackButton
                        size="1.115rem"
                        color="rougeFort"
                        onClick={closeModal}

                    />
                </FlexDiv>
                {
                    !isNotCustomer && (
                        <FlexDiv
                            style={{justifyContent: 'center', flex: 2}}
                        >
                            <CabadiciButton
                                isSubHeaderTabs
                                color="rougeFaible"
                                onClick={()=>{
                                    // alert('votre commande est validée')
                                    // process to order
                                    if (isAnOrderUpdate) {
                                        return PaymentRef.current.updateCurrentOrder(orderId);
                                    }
                                    return PaymentRef.current.submitNewOrder();
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 1.5rem !important;
                                    }
                                `}
                            >
                                valider ma commande
                            </CabadiciButton>
                        </FlexDiv>
                    )
                }
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                />
            </FlexDiv>
        </>
    );
}

ConfirmOrder.propTypes = {
    closeModal: PropTypes.func.isRequired,
    data: PropTypes.shape({message: PropTypes.string}),
};

export default Modalize(ConfirmOrder, {showCloseIcon:false});
