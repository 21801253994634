import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import Modalize from '../../components/Modalizer_';
import { Text as TextWithoutStyle, Title } from '../../components1/Texts';
import FlexDiv from '../../components1/FlexDiv';
import { CabadiciButton, BackButton } from '../../components1/buttons';
import Separator from '../../components1/Separator';

const Text = styled(TextWithoutStyle)`
    text-align: left;
    @media only screen and (min-width: 599px) {
        text-align: center !important;
    }
`;

const AlreadyOrdered = ({ onSubmit, closeModal}) => {

    return (
        <>
            <FlexDiv
                style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                css={`
                    @media only screen and (min-width: 599px) {
                        min-width: 300px;
                    }
                `}
            >
                <Title
                    css={`
                        padding-bottom: 10px;
                        @media only screen and (max-width: 599px) {
                            font-size: 1.5rem !important;
                            text-align: center !important;
                        }
                    `}
                >Commande déjà enregistrée</Title>
                <Text style={{padding: 5}}>Votre commande de la semaine a déjà été validée.</Text>
                <Text style={{padding: 5}}>Retrouvez le détail de votre CABADICI dans votre espace <span
                    role="button"
                    style={{
                        textDecoration: 'underline',
                    }}
                    css={`
                        :hover{
                            cursor: pointer;
                        }
                    `}
                    onClick={() => {
                        navigate('/user/orders')
                    }}
                >
                    Mes Commandes
                </span>.</Text>
                <Text style={{padding: 5}}>{'Vous pouvez la modifier jusqu\'à dimanche prochain.'}</Text>
                <br />
            </FlexDiv>
            <Separator strokeColor="vertFaible" style={{marginTop: 20}}/>
            <FlexDiv style={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-start'
            }}>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                >
                    <BackButton
                        size="1.115rem"
                        color="jauneFort"
                        onClick={() => {
                            navigate('/user')
                        }}

                    />
                </FlexDiv>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 3}}
                >
                    {
                        <CabadiciButton
                            color="rougeFort"
                            onClick={() => {
                                onSubmit();
                                closeModal();
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    font-size: 1.625rem !important;
                                }
                            `}
                        >
                            Modifier ma commande
                        </CabadiciButton>
                    }
                </FlexDiv>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                />
            </FlexDiv>
        </>
    );
};

export default Modalize(AlreadyOrdered, {showCloseIcon: false});
