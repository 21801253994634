import React from 'react';
import { Layout } from '../../components1/layout';

import MyCabadici from '../../containers/user/MyCabadici';

const UserCabadiciPage = () => {
    return (
        <Layout isUserPage>
            {
                () => (
                    <MyCabadici />
                )
            }
        </Layout>
    );
};

export default UserCabadiciPage;
